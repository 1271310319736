import React from "react";
import {
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import EcnInput from "../StyledComponents/EcnInput";
import EcnButton from "../StyledComponents/EcnButton"; // Assuming this is your custom button component

interface AppData {
  appData: any;
  setAppData: any;
}

const QuestionStep = ({ appData, setAppData }: AppData) => {
  const handleInputChange = (index: number, value: any) => {
    const trimmedValue = value.trim();

    const updatedQuestions = appData.job_questions.map((question: any, i: number) =>
      i === index
        ? { ...question, values: trimmedValue ? [trimmedValue] : [] } // If value is empty, store an empty array
        : question
    );

    setAppData({ ...appData, job_questions: updatedQuestions });
  };


  const handleCheckboxChange = (index: number, value: string) => {
    const updatedQuestions = appData.job_questions.map(
      (question: any, i: number) => {
        if (i === index) {
          const currentValues = question.values || [];
          const newValues = currentValues.includes(value)
            ? currentValues.filter((v: string) => v !== value)
            : [...currentValues, value];
          return { ...question, values: newValues };
        }
        return question;
      }
    );
    setAppData({ ...appData, job_questions: updatedQuestions });
  };


  return (
    <div className="question-step">
      {appData?.job_questions?.map((question: any, index: number) => (
        <div className="question-wrapper" key={index}>
          <p className="que">{question?.name}{question?.required ? "*" : ""}</p>
          {question.type === 0 && (
            <EcnInput
              className="ecn-input"
              size="small"
              fullWidth
              required={question?.required}
              value={question?.values?.[0] || ""}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          )}
          {question.type === 2 && (
            <RadioGroup
              value={question?.values?.[0] || ""}
              onChange={(e) => handleInputChange(index, e.target.value)}
            >
              {question?.options?.map((option: string) => (
                <FormControlLabel
                  className="choice-label"
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          )}
          {question.type === 5 && (
            <FormGroup>
              {question?.options?.map((option: string) => (
                <FormControlLabel
                  key={option}
                  className="choice-label"
                  control={
                    <Checkbox
                      checked={question?.values?.includes(option) || false}
                      onChange={() => handleCheckboxChange(index, option)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormGroup>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionStep;
