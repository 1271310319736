import React, { useEffect, useState } from "react";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { getJobDetail } from "../redux/slices/jobSlices/getJobDetailSlice";
import { Dialog, DialogContent, Divider, LinearProgress } from "@mui/material";
import BasicStep from "../components/Apply/BasicStep";
import EducationStep from "../components/Apply/EducationStep";
import WorkStep from "../components/Apply/WorkStep";
import QuestionStep from "../components/Apply/QuestionStep";
import PreviewStep from "../components/Apply/PreviewStep";
import { getUniAndClub } from "../redux/slices/jobSlices/getUniAndClubSlice";

import { postJobApply } from "../redux/slices/jobSlices/postJobApplySlice";
import { correctEmail } from "../helper/Validations/BasicValidations";

import Lottie from "react-lottie";
import SuccessLottie from "../assets/lotties/Success_Job.json";
import Loader from "../components/UI/Loader";
import NotFound from "./NotFound";

const REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT || "";

const Apply = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { jobId, companyName } = useParams<{
    jobId: string;
    companyName: string;
  }>();

  const { jobData, loading } = useAppSelector((state) => state.getJobDetails);
  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { applyResponse } = useAppSelector((state) => state.postJobApply);

  const [activeStep, setActiveStep] = useState<number>(1);
  const [fileName, setFileName] = useState<string>("");
  const [noWorkExp, setNoWorkExp] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [appData, setAppData] = useState({
    job_id: "",
    user_id: "",
    basic_details: { first_name: "", last_name: "", email: "", phone: "" },
    resume: "",
    academic_scores: { act: 0, gpa: 0, sat: 0, gmat: 0 },
    education: [
      {
        education_type: 0,
        graduation_year: 0,
        name: "",
        partner_club: "",
      },
    ],
    work_experiences: [
      {
        city: "",
        company: "",
        end_date: "2030-10-05T14:48:00.000Z",
        experience_status: 0,
        experience_type: 0,
        industry: "",
        position: "",
        start_date: "",
        title: "",
        zip_code: "",
        logo: "",
        description: "",
      },
    ],
    job_questions: [
      {
        name: "",
        type: 0,
        key: "",
        save: false,
        options: [],
        values: [],
        required: false,
      },
    ],
  });

  useEffect(() => {
    dispatch(getJobDetail({ jobId: jobId }));
  }, []);

  useEffect(() => {
    if (jobData && jobData.job_questions)
      setAppData({
        ...appData,
        job_id: jobData._id,
        job_questions: jobData.job_questions,
      });
  }, [jobData]);

  useEffect(() => {
    if (userInfo) {
      setAppData({
        ...appData,
        resume: userInfo.resume,
        user_id: userInfo._id,
        basic_details: {
          ...appData.basic_details,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          email: userInfo.email,
          phone: userInfo.phone,
        },
        academic_scores: {
          sat: userInfo.sat,
          gpa: userInfo.gpa,
          gmat: userInfo.gmat,
          act: userInfo.act,
        },
        education: userInfo.education,
        work_experiences: (!userInfo?.work_experiences || userInfo.work_experiences.length === 0)
          ? appData?.work_experiences
          : userInfo.work_experiences,

      });
      const split = userInfo.resume.split("/");
      setFileName(split[split.length - 1]);
    }
  }, [userInfo]);

  useEffect(() => {
    if (appData?.resume === null && userInfo?.resume) {
      setAppData({
        ...appData,
        resume: userInfo.resume,
      })
    }
  }, [userInfo, appData]);

  useEffect(() => {
    dispatch(getUniAndClub());
  }, []);

  useEffect(() => {
    if (applyResponse && submitClick) {
      setSuccessView(true);
      setSubmitClick(false);
    }
  }, [applyResponse, submitClick]);

  const isStepValid = () => {
    switch (activeStep) {
      case 1:
        const { first_name, last_name, email, phone } = appData.basic_details;
        const { resume } = appData;
        return (
          first_name &&
          last_name &&
          email &&
          correctEmail.test(email) &&
          phone &&
          !mobileError
          &&
          resume !== "" &&
          resume != undefined
        );
      case 2:
        const { gpa } = appData.academic_scores;
        return (
          gpa > 0 &&
          appData?.education?.every((ed) => ed.education_type != null && ed.name)
        );
      case 3:
        // Check for noWorkExp flag and validate work experiences if not true
        const hasWorkExperience =
          appData?.work_experiences?.length > 0 &&
          appData?.work_experiences?.every(
            (work) =>
              work.company &&
              work.title &&
              work.position &&
              work.experience_type !== 0 &&
              work.experience_type !== null &&
              work.start_date &&
              work.city &&
              work.city.trim() !== ""
          );

        // If noWorkExp is true, automatically make step valid
        const isValidStep3 = noWorkExp ? true : hasWorkExperience;

        return isValidStep3;
      case 4:
        return (
          appData?.job_questions?.length > 0 &&
          appData?.job_questions?.every((question) =>
            question.required ? question.values?.length > 0 : true
          )
        );
      default:
        return true;
    }
  };

  const backHandler = () => {
    navigate(`/${companyName}/job/${jobId}`);
  };

  const nextHandler = () => {

    setActiveStep((prev) => {
      if (prev === 3) {
        if (appData.job_questions.length > 0) {
          return prev + 1;
        } else {
          return prev + 2;
        }
      } else {
        return prev + 1;
      }
    });
  };

  const submitHandler = () => {
    setSubmitClick(true);
    dispatch(postJobApply({ ...appData, job_id: jobId }));
  };

  const applyWithOnefinnetHandler = () => {
    const currentUrl = window.location.href;
    const redirectUrl = `${REDIRECT_URL}?origin=${encodeURIComponent(currentUrl)}`;
    window.location.href = redirectUrl;
  };

  const popupCloseHandler = () => {
    setSuccessView(false);
    navigate(`/${companyName}`);
  };

  const backStepHandler = () => {
    if (appData.job_questions.length > 0 && activeStep === 5) {
      setActiveStep((prev) => prev - 1)
    }
    else if (appData.job_questions.length === 0 && activeStep === 5) {
      setActiveStep((prev) => prev - 2)
    }
    else {
      setActiveStep((prev) => prev - 1)
    }
  }
  console.log("noWorkExp", noWorkExp)
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : jobData ? (
        <div className="job-apply">
          <div className="head">
            <EcnButton
              color="secondary"
              startIcon={<Icon icon="material-symbols:arrow-back" />}
              onClick={backHandler}
            >
              Back
            </EcnButton>
            <div className="icon">
              <p className="talent">Talent</p>
              <div className="divider-vert" />
              <img src="/b_elevate_2.png" alt="" />
            </div>
          </div>

          {activeStep < 5 ? (
            <div className="job-card">
              <div className="title">
                <div className="title-data">
                  <img
                    className="firm-logo"
                    src={jobData?.display_picture}
                    alt=""
                  />

                  <div className="data">
                    <p className="company">{jobData?.organisations?.[0]}</p>
                    <p className="job-title">{jobData?.job_title}</p>
                    <div className="row">
                      <p>
                        <Icon icon="lucide:briefcase-business" />{" "}
                        {jobData?.role_type}
                      </p>
                      <p>
                        <Icon icon="lucide:map-pin" />{" "}
                        {jobData?.address?.cities?.[0]}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="btn-group">
                  <EcnButton
                    variant="contained"
                    className="apply-btn"
                    onClick={applyWithOnefinnetHandler}
                  >
                    Apply with Onefinnet
                  </EcnButton>
                </div>
              </div>
              <div className="meta-data">
                <div className="block">
                  <p className="heading">Industry: </p>
                  <p className="info">{jobData?.industries?.[0]}</p>
                </div>

                <div className="block">
                  <p className="heading">Experience: </p>
                  <p className="info">
                    {jobData?.experience_required +
                      " - " +
                      jobData?.max_experience_required}{" "}
                    years
                  </p>
                </div>

                <div className="block">
                  <p className="heading">Posted on: </p>
                  <p className="info">
                    {moment.unix(jobData?.createdAt).format("LL")}
                  </p>
                </div>
              </div>

              <Divider />

              <div className="apply-container">
                <div className="apply-step_count">
                  <p className="step-heading">
                    {activeStep === 1
                      ? "Basic Details"
                      : activeStep === 2
                        ? "Education"
                        : activeStep === 3
                          ? "Work Experience"
                          : activeStep === 4
                            ? "Questions"
                            : ""}
                  </p>
                  <div className="progress">
                    <p className="step">
                      <span>{activeStep}</span> of{" "}
                      {appData?.job_questions?.length > 0 ? 4 : 3}
                    </p>
                    <div className="progress-wrapper">
                      <LinearProgress
                        variant="determinate"
                        value={
                          activeStep *
                          (appData?.job_questions?.length > 0 ? 25 : 33.33)
                        }
                        className="job-progress"
                      />
                    </div>
                  </div>
                </div>
                <div className="apply-steps">
                  {activeStep === 1 ? (
                    <BasicStep
                      appData={appData}
                      setAppData={setAppData}
                      fileName={fileName}
                      setFileName={setFileName}
                      mobileError={mobileError}
                      setMobileError={setMobileError}
                    />
                  ) : activeStep === 2 ? (
                    <EducationStep appData={appData} setAppData={setAppData} />
                  ) : activeStep === 3 ? (
                    <WorkStep
                      appData={appData}
                      setAppData={setAppData}
                      setNoWorkExp={setNoWorkExp}
                      noWorkExp={noWorkExp}
                    />
                  ) : activeStep === 4 ? (
                    <QuestionStep appData={appData} setAppData={setAppData} />
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <PreviewStep appData={appData} noWorkExp={noWorkExp} />
          )}

          <div className="btn-nav">
            {activeStep === 1 ? (
              <div />
            ) : (
              <EcnButton
                variant="outlined"
                color="secondary"
                className="back-btn"
                onClick={backStepHandler}
              >
                Back
              </EcnButton>
            )}
            {activeStep === 5 ? (
              <EcnButton
                variant="contained"
                className="next-btn"
                onClick={submitHandler}
              >
                Submit
              </EcnButton>
            ) : (
              <EcnButton
                variant="contained"
                className="next-btn"
                onClick={nextHandler}
                disabled={!isStepValid()}
              >
                Next
              </EcnButton>
            )}
          </div>

          <Dialog open={successView} onClose={popupCloseHandler}>
            <DialogContent sx={{ padding: "40px 120px" }}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: SuccessLottie,
                }}
                height={200}
                width={200}
              />
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                Your application successfully submitted!
              </p>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Apply;

// const data = {
//   job_id: "12345",
//   user_id: "67890",
//   basic_details: {
//     first_name: "Aamir",
//     last_name: "Khan",
//     email: "aamir.khan@example.com",
//     phone: "123-456-7890",
//   },
//   resume: "path/to/resume.pdf",
//   academic_scores: { act: 30, gpa: 3.8, sat: 1400, gmat: 700 },
//   education: [
//     {
//       education_type: 1,
//       graduation_year: 2022,
//       name: "Example University",
//       partner_club: "Business Club",
//     },
//   ],
//   work_experiences: [
//     {
//       city: "New York",
//       company: "Tech Corp",
//       end_date: "2030-10-05T14:48:00.000Z",
//       experience_status: 1,
//       experience_type: 1,
//       industry: "Technology",
//       position: "Software Engineer",
//       start_date: "2025-01-01",
//       title: "Senior Developer",
//       zip_code: "10001",
//       logo: "path/to/logo.png",
//       description: "Developed cutting-edge software solutions.",
//     },
//   ],
//   job_questions: [
//     {
//       name: "How many years of experience do you have in Venture Capital, Investing, Start-ups or Related Fields? (Note: VC experience is not required, we just want to get a sense)",
//       type: 2,
//       key: "",
//       save: false,
//       options: [
//         "No experience",
//         "Less than 1 year",
//         "1-2 years",
//         "3-4 years",
//         "5+ years",
//       ],
//       values: ["1-2 years"],
//       required: true,
//     },
//     {
//       name: "What describes your work Authorization status",
//       type: 2,
//       key: "",
//       save: false,
//       options: [
//         "US Citizen",
//         "US Green Card",
//         "Other Work Authorization to work in US",
//         "International Citizen / STEM OPT",
//         "International Citizen / No STEM OPT Status",
//         "Other",
//       ],
//       values: ["Other Work Authorization to work in US"],
//       required: true,
//     },
//     {
//       name: "Which of the following financial software are you proficient in?",
//       type: 5,
//       key: "",
//       save: false,
//       options: ["Microsoft Excel", "CapIQ", "Pitchbook", "None"],
//       values: ["Microsoft Excel", "CapIQ", "Pitchbook"],
//       required: true,
//     },
//     {
//       name: "What year do you graduate your MBA program",
//       type: 2,
//       key: "",
//       save: false,
//       options: ["2024", "2025", "Other"],
//       values: ["2025"],
//       required: true,
//     },
//     {
//       name: "Can you work in person in NYC?",
//       type: 2,
//       key: "",
//       save: false,
//       options: ["Yes", "No"],
//       values: ["No"],
//       required: true,
//     },
//   ],
// };
