import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";

const PreviewStep: React.FC<{ appData: any; noWorkExp: any }> = ({
  appData,
  noWorkExp,
}) => {
  const split = appData?.resume?.split("/");
  let resumeName = "";
  if (split) {
    resumeName = split[split.length - 1];
  }
  return (
    <div className="preview">
      <h1>Review Application</h1>

      <div className="cards">
        <div className="basic">
          <div className="basic-top">
            <h3>Enter basic Details</h3>
            <Icon icon="lucide:pen" />
          </div>
          <div className="row">
            <div className="left">
              <p className="label">First Name*</p>
              <div className="value">
                <p>{appData?.basic_details?.first_name}</p>
              </div>
            </div>

            <div className="left">
              <p className="label">Last Name*</p>
              <div className="value">
                <p>{appData?.basic_details?.last_name}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="left">
              <p className="label">Email*</p>
              <div className="value">
                <p>{appData?.basic_details?.email}</p>
              </div>
            </div>

            <div className="left">
              <p className="label">Phone number*</p>
              <div className="value">
                <p>{appData?.basic_details?.phone}</p>
              </div>
            </div>
          </div>
          <p className="score">Score</p>
          <div className="row">
            <div className="left ">
              <div className="value">
                <p>{appData?.academic_scores?.gpa}</p>
              </div>
            </div>

            <div className="left ">
              <div className="value">
                <p>{appData?.academic_scores?.sat}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="left ">
              <div className="value">
                <p>{appData?.academic_scores?.act}</p>
              </div>
            </div>

            <div className="left ">
              <div className="value">
                <p>{appData?.academic_scores?.gmat}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="left ">
              <p className="label">Resume*</p>
              <h2 className="pdf-name">{resumeName}</h2>
            </div>
          </div>
        </div>

        <div className="education">
          <div className="top">
            <h3>Education</h3>
            <Icon icon="lucide:pen" />
          </div>

          {appData?.education?.map((item: any, index: number) => (
            <div className="row" key={index}>
              <div className="left">
                <p className="label">Education Level*</p>
                <div className="value">
                  <p>{item?.education_type}</p>
                  <Icon icon="lucide:chevron-down" />
                </div>
              </div>

              <div className="left">
                <p className="label">University</p>
                <div className="value">
                  <p>{item?.name}</p>
                </div>
              </div>

              <div className="left">
                <p className="label">Graduation Year</p>
                <div className="value">
                  <p>{item?.graduation_year}</p>
                  <Icon icon="lucide:chevron-down" />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="education">
          <div className="top">
            <h3>Work Experience</h3>
            <Icon icon="lucide:pen" />
          </div>

          <div className="check-box">
            {noWorkExp ? (
              <div className="check">
                <Icon icon="lucide:check" color="#ffffff" />
              </div>
            ) : (
              <div
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  width: "18px",
                  height: "18px",
                  borderRadius: "3px",
                }}
              ></div>
            )}
            <p>I do not have relevant work experience</p>
          </div>

          {appData?.work_experiences?.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <div className="row">
                <div className="left work-left">
                  <p className="label">Company Name*</p>
                  <div className="value">
                    <p>{item?.company}</p>
                    <Icon icon="lucide:chevron-down" />
                  </div>
                </div>

                <div className="left work-left">
                  <p className="label">Job Function*</p>
                  <div className="value">
                    <p>{item?.title}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="left work-left">
                  <p className="label">Job Role*</p>
                  <div className="value">
                    <p>{item?.company}</p>
                    <Icon icon="lucide:chevron-down" />
                  </div>
                </div>

                <div className="left work-left">
                  <p className="label">Experience Type*</p>
                  <div className="value">
                    <p>{item?.title}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="left work-left">
                  <p className="label">Start Date</p>
                  <div className="value">
                    {!noWorkExp && (
                      <>
                        <p>{moment(item?.start_date).format("MM/DD/YYYY")}</p>
                        <Icon icon="lucide:chevron-down" />
                      </>
                    )}
                  </div>
                </div>

                <div className="left work-left">
                  <p className="label">End Date</p>
                  <div className="value">
                    {item?.end_date != "030-10-05T14:48:00.000Z" && (
                      <p>{moment(item?.end_date).format("MM/DD/YYYY")}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="left" style={{ width: "100%" }}>
                  <p className="label">Job Location*</p>
                  <div className="value">
                    <p>{item?.city}</p>
                  </div>
                </div>
              </div>

              <div className="check-box">
                {item?.end_date === "2030-10-05T14:48:00.000Z" ? (
                  <div className="check">
                    <Icon icon="lucide:check" color="#ffffff" />
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid rgba(0,0,0,0.2)",
                      width: "18px",
                      height: "18px",
                      borderRadius: "3px",
                    }}
                  ></div>
                )}
                <p>I currently work here</p>
              </div>
            </React.Fragment>
          ))}
        </div>

        {appData?.job_questions?.length > 0 && (
          <div className="question">
            <div className="top">
              <h3>Job Questions</h3>
              <Icon icon="lucide:pen" />
            </div>

            {appData?.job_questions?.map((item: any, index: number) => (
              <React.Fragment key={index}>
                {item?.type === 0 ? (
                  <div className="row">
                    <p className="que">{item?.name}</p>
                    <div className="ans">
                      <p>{item?.values?.[0]}</p>
                    </div>
                  </div>
                ) : item?.type === 2 ? (
                  <div className="radio">
                    <p className="que">{item?.name}</p>
                    {item?.options?.map((op: any, opIndex: number) => (
                      <div className="ans" key={opIndex}>
                        {item?.values?.includes(op) ? (
                          <div className="rad">
                            <div className="iner"></div>
                          </div>
                        ) : (
                          <div className="empty"></div>
                        )}
                        <p>{op}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="radio">
                    <p className="que">{item?.name}</p>
                    {item?.options?.map((op: any, opIndex: number) => (
                      <div className="ans" key={opIndex}>
                        {item?.values?.includes(op) ? (
                          <div className="check-box">
                            <div className="check">
                              <Icon icon="lucide:check" color="#ffffff" />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              border: "1px solid rgba(0,0,0,0.2)",
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                          ></div>
                        )}
                        <p>{op}</p>
                      </div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewStep;
